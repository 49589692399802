(function () {
    'use strict';

    angular.module('aerosApp').controller('ToolPresetsController', ToolPresetsController);

    ToolPresetsController.$inject = ['$scope', '$state', 'userInfo', 'toolPresets', 'PresetService', 'Notification'];

    function ToolPresetsController($scope, $state, userInfo, toolPresets, PresetService, Notification) {

        var vm = this;
        vm.license = userInfo.organization.license;
        $state.license = vm.license;

        (function init() {

            angular.extend(vm, {
                reload: reload,
                license: $state.license,
                toolType:  $state.params.toolType,
                presetType: $state.params.presetType
            });

            setPresets(toolPresets);
        })();

        function reload() {
            PresetService(vm.toolType).fetch($state.params.id).// then(function() {vm.presets = [];}).
            then(setPresets).catch(Notification.error.bind(Notification));
        }

        function setPresets(presets) {
            vm.presets = presets[vm.toolType] ? presets[vm.toolType][vm.presetType] : [];
        }

    }

}());
